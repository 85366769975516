import Contact from "./Contact";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { ScrollRestoration } from "react-router-dom";
function ContactTab() {
  return (
    <div>
     <Navbar/>
     <div className="mt-40"><Contact/></div>
     
     <Footer/>
      <ScrollRestoration />
    </div>
  );
}

export default ContactTab;