import React, { useState } from 'react';
import { Link,  ScrollRestoration } from 'react-router-dom';
import 'animate.css';

function Contact() {
  const [formData, setFormData] = useState({
    firstname: '',
    email: '',
    telephone: '',
    message: '',
  });
  const [canSubmit, setCanSubmit] = useState(false);

  const sentEmail = (e) => {
    setCanSubmit(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://petromin.sk/phpmailer/sendEmail.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData),
    })
      .then((response) => response.text())
      .then((data) => {
        alert(data); 

      
        setFormData({
          firstname: '',
          email: '',
          telephone: '',
          message: '',
        });


        setCanSubmit(false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="m-auto p-4 mt-20 rounded-lg mb-20 animate__animated animate__bounceInRight">
      <h2 className="p-4 flex justify-center font-bold text-7xl max-md:text-3xl max-md:text-2xl">Kontakt</h2>
      <p className="p-4 text-center">
        Chcete získať bezplatnú cenovú ponuku? Neváhajte nás kontaktovať.
        Stačí vyplniť tento formulár a my sa postaráme o všetko ostatné.
        Po prijatí vašej žiadosti vás čo najskôr oslovíme a dohodneme všetky podrobnosti.
      </p>
      <div className="flex w-full justify-center flex-wrap">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1305.9504909034154!2d21.505501604674382!3d49.107521521468314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473e97030911bc1b%3A0xb3a5606c0042e89b!2sPETROMIN%2C%20spol.%20s.r.o.!5e0!3m2!1ssk!2ssk!4v1717443646955!5m2!1ssk!2ssk"
          width="600"
          height="600"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="rounded-lg shadow-2xl"
        ></iframe>
        <form className="space-y-4 mt-36 lg:ml-20" onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              id="fname"
              name="firstname"
              placeholder="Vaše meno a priezvisko.."
              className="rounded w-96 border-b-2 border-black p-2"
              value={formData.firstname}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Váš email.."
              className="rounded w-96 border-b-2 border-black p-2"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <input
              type="text"
              id="telephone"
              name="telephone"
              placeholder="Vaše telefónne číslo.."
              className="rounded w-96 border-b-2 border-black p-2"
              value={formData.telephone}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <textarea
              id="message"
              name="message"
              placeholder="Správa.."
              className="rounded w-96 border-b-2 border-black p-2"
              value={formData.message}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex items-center w-96 mb-6">
            <input
              type="checkbox"
              className="mr-2"
              checked={canSubmit}
              onChange={sentEmail}
            />
            <div className="flex-1">
              Oboznámil(a) som sa s podmienkami ochrany osobných údajov a súhlasím so spracovaním údajov uvedených vo formulári
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className={`rounded bg-black text-white p-4 ${canSubmit ? '' : 'opacity-50 cursor-not-allowed'}`}
              disabled={!canSubmit}
            >
              Odošli podnet
            </button>
          </div>
        </form>
      </div>
      <ScrollRestoration />
    </div>
  );
}

export default Contact;
