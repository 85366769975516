import React, { useState } from "react";
import 'animate.css';
import Logo from "../resources/Logo petromin.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useLocation, Link } from "react-router-dom";

function Navbar() {
  const [hamburgerState, setHamburgerState] = useState(false);
  const location = useLocation();
  const toggleHamburger = () => {
    setHamburgerState((prevState) => !prevState);
    console.log(hamburgerState);
  };

  return (
    <div>
      <nav className="bg-white shadow-xl z-50 fixed top-0 left-0 w-full">
        <div className="absolute top-0 left-0 w-full h-4 bg-red-600"></div>
        <div className="p-4 flex justify-between items-center mx-auto h-32">
          <img src={Logo} alt="logo" className="h-14" />
          <div className="flex items-center space-x-8 text-2xl font-semibold hidden lg:flex">
            <Link to="/" className="p-2">Domov</Link>
            <Link to="/ponuka" className="p-2">Ponuka</Link>
            <Link to="/doprava" className="p-2">Doprava</Link>
            <Link to="/contact" className="p-2">Kontakt</Link>
          </div>
          <div className={"lg:hidden"} onClick={toggleHamburger}>
            <AiOutlineMenu className="h-8 w-8" />
          </div>
        </div>
      </nav>

      {hamburgerState && (
        <div className="fixed top-0 left-0 bg-black bg-opacity-70 w-full h-full z-50 animate__animated animate__backInLeft">
          <div className="absolute top-0 left-0 w-3/5 h-full p-10 bg-white ease-out duration-1000 opacity-100 overflow-y-auto hide-scrollbar">
            <div className="flex justify-between items-center">
              <div>
                <Link to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
              </div>
     
              <AiOutlineClose
                onClick={toggleHamburger}
                className="h-8 w-8 ml-4 rounded-xl border cursor-pointer shadow-lg p-1 hover:bg-red-600 hover:text-white"
              />
            </div>
            <div className="font-medium text-lg border-b border-gray-300">
              <p className="block my-4">Petromin</p>
            </div>
            <div>
              <ul className="space-y-4 p-4 font-bold text-xl mt-8">
                <li>
                  <Link to="/" onClick={toggleHamburger} className="block">Domov</Link>
                </li>
                <li>
                  <Link to="/ponuka" onClick={toggleHamburger} className="block">Ponuka</Link>
                </li>
                <li>
                  <Link to="/doprava" onClick={toggleHamburger} className="block">Doprava</Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toggleHamburger} className="block">Kontakt</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
