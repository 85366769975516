import React from 'react';
import Navbar from "./Navbar";
import Contact from './Contact';
import Footer from './Footer';
import strkopiesky from '../resources/strkopiesky.jpg';
import piesok from '../resources/piesok.jpg';
import kamen from '../resources/Kamenivo 8-16.jpeg';
import dekorativne from '../resources/dekorativne.jpg';
import { Link,  ScrollRestoration } from 'react-router-dom';
import 'animate.css';

function Home() {
  const items = [
    {
      img: strkopiesky,
      title: 'ŠTRKOPIESKY',
      description: 'Štrkopiesok je používaný v stavebníctve pre výrobu betónu a základových vrstiev. Ideálna voľba pre rôzne stavebné projekty.',
      link: '/ponuka/strkopiesky'
    },
    {
      img: kamen,
      title: 'KAMENIVO',
      description: 'Kamenivo je základným stavebným materiálom, využívaným na vytváranie pevných a stabilných konštrukcií.',
      link: '/ponuka/kamenivo'
    },
    {
      img: piesok,
      title: 'PIESOK',
      description: 'Piesok je ideálny pre prípravu malty a iných stavebných zmesí vďaka svojej jemnej zrnitosti a konzistencii.',
      link: '/ponuka/piesok'
    },
    {
      img: dekorativne,
      title: 'DEKORATÍVNE',
      description: 'Dekoratívne materiály pridávajú estetickú hodnotu a unikátny vzhľad každému projektu.',
      link: '/ponuka/okrasny'
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="mt-48 max-w-screen-xl mx-auto px-6">
        <div className="text-center text-6xl font-bold mb-20 text-gray-800">
          Naša <span className="text-red-600">ponuka</span>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 animate__animated animate__bounceInLeft ">
          {items.map((item, index) => (
            <div key={index} className="relative w-full rounded-lg overflow-hidden shadow-xl flex flex-col">
              <img src={item.img} className="w-full h-80 object-cover" alt={item.title} />
              <div className="absolute inset-0 bg-white bg-opacity-70 text-black text-2xl p-6 flex flex-col justify-between">
                <div>
                  <h3 className="font-bold text-3xl text-red-600">{item.title}</h3>
                  <p className="text-gray-800 mt-2">{item.description}</p>
                </div>
                <Link to={item.link} className="mt-4 bg-red-600 text-white py-3 px-6 rounded">Viac informácií</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Contact />
      <Footer />
      <ScrollRestoration />
    </div>
  );
}

export default Home;
