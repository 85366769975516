import React from 'react';
import { ScrollRestoration, useNavigate } from 'react-router-dom';
import Navbar from "./Navbar";
import dekorativne from '../resources/dekorativne.jpg';
import kamen from '../resources/Kamenivo 8-16.jpeg';
import piesok from '../resources/piesok.jpg';
import strkopiesky from '../resources/strkopiesky.jpg';
import Footer from './Footer';
import 'animate.css';

function Ponuka() {
    const navigate = useNavigate();

    const handleRoute = (route) => {
        return (event) => {
            event.preventDefault(); 
            navigate(route);
        };
    };

    return (
        <div>
            <Navbar />
            <div className="text-center mt-48 text-5xl font-extrabold mb-16 text-gray-800">
                Naša <span className="text-red-600">ponuka</span>
            </div>
            <div className="flex justify-center max-w-full w-11/12 m-auto rounded-2xl space-x-4 max-md:space-x-0 max-md:flex-wrap mb-56 animate__animated animate__backInLeft">
                <div className="relative h-64 w-1/4 max-md:w-full max-md:mb-4" onClick={handleRoute('/ponuka/strkopiesky')}>
                    <img src={strkopiesky} className="h-full w-full object-cover rounded-lg" alt="ŠTRKOPIESKY" />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity ease-in-out duration-300 text-white text-2xl font-semibold">
                        ŠTRKOPIESKY
                    </div>
                </div>

                
                <div className="relative h-64 w-1/4 max-md:w-full max-md:mb-4" onClick={handleRoute('/ponuka/kamenivo')}>
                    <img src={kamen} className="h-full w-full object-cover rounded-lg" alt="KAMENIVO" />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity ease-in-out duration-300 text-white text-2xl font-semibold">
                        KAMENIVO
                    </div>
                </div>

             
                <div className="relative h-64 w-1/4 max-md:w-full max-md:mb-4" onClick={handleRoute('/ponuka/piesok')}>
                    <img src={piesok} className="h-full w-full object-cover rounded-lg" alt="PIESOK & ZEMINA" />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity ease-in-out duration-300 text-white text-2xl font-semibold">
                        PIESOK & ZEMINA
                    </div>
                </div>

            
                <div className="relative h-64 w-1/4 max-md:w-full" onClick={handleRoute('/ponuka/okrasny')}>
                    <img src={dekorativne} className="h-full w-full object-cover rounded-lg" alt="OKRASNÝ KAMEŇ" />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity ease-in-out duration-300 text-white text-2xl font-semibold">
                        OKRASNÝ KAMEŇ
                    </div>
                </div>
            </div>
            <div className='bottom-0 relative'><Footer /></div> 
        <ScrollRestoration />
        </div>
    );
}

export default Ponuka;
