import { useState } from "react";
import DOMPurify from "dompurify";

const Formular = ({ napln }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telefon, setTelefon] = useState('');
    const [prebratie, setPrebratie] = useState('');
    const [mnozstvo, setMnozstvo] = useState('');
    const [info, setInfo] = useState('');
    const [produkt, setProdukt] = useState('');
    const [canOrder, setCanOrder] = useState(false);
    const [errors, setErrors] = useState({});

    // Function to toggle the canOrder state based on checkbox
    const canOrderForm = (e) => {
        setCanOrder(e.target.checked);
    };

    // Validation functions
    const validateName = (name) => {
        if (!name.trim()) {
            return 'Meno je povinné';
        }
        return '';
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!re.test(String(email).toLowerCase())) {
            return 'Zadajte platnú emailovú adresu';
        }
        return '';
    };

    const validatePhoneNumber = (telefon) => {
        const re = /^\d{10}$/; 
        if (!re.test(String(telefon))) {
            return 'Telefónne číslo je neplatné, zadajte 10 čísel bez medzier';
        }
        return '';
    };

    const validateProdukt = (produkt) => {
        if (!produkt) {
            return 'Vyberte produkt';
        }
        return '';
    };

    const validateMnozstvo = (mnozstvo) => {
        if (!mnozstvo || isNaN(mnozstvo) || Number(mnozstvo) <= 0 || Number(mnozstvo) > 500) {
            return 'Zadajte platné množstvo v tonách (max. 500 ton)';
        }
        return '';
    };

    const validatePrebratie = (prebratie) => {
        if (!prebratie) {
            return 'Vyberte možnosť prebratia';
        }
        return '';
    };

    const handleBlur = (field, value) => {
        let error = '';
        switch (field) {
            case 'name':
                error = validateName(value);
                break;
            case 'email':
                error = validateEmail(value);
                break;
            case 'telefon':
                error = validatePhoneNumber(value);
                break;
            case 'produkt':
                error = validateProdukt(value);
                break;
            case 'mnozstvo':
                error = validateMnozstvo(value);
                break;
            case 'prebratie':
                error = validatePrebratie(value);
                break;
            default:
                break;
        }
        setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

       
        const validationErrors = {
            name: validateName(name),
            email: validateEmail(email),
            telefon: validatePhoneNumber(telefon),
            produkt: validateProdukt(produkt),
            prebratie: validatePrebratie(prebratie),
            mnozstvo: validateMnozstvo(mnozstvo),
        };

       
        if (Object.values(validationErrors).some((error) => error !== '')) {
            setErrors(validationErrors);
            return;
        }


        const sanitizedData = {
            name: DOMPurify.sanitize(name),
            email: DOMPurify.sanitize(email),
            telefon: DOMPurify.sanitize(telefon),
            prebratie: DOMPurify.sanitize(prebratie),
            mnozstvo: DOMPurify.sanitize(mnozstvo),
            info: DOMPurify.sanitize(info),
            produkt: DOMPurify.sanitize(produkt),
        };

        const formData = new URLSearchParams();
        formData.append('name', sanitizedData.name);
        formData.append('email', sanitizedData.email);
        formData.append('telefon', sanitizedData.telefon);
        formData.append('prebratie', sanitizedData.prebratie);
        formData.append('mnozstvo', sanitizedData.mnozstvo);
        formData.append('info', sanitizedData.info);
        formData.append('produkt', sanitizedData.produkt);

        try {
            const response = await fetch('https://petromin.sk/phpmailer/orderHandler.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData.toString(),
            });

            const data = await response.json();

            if (data.status === 'success') {
                alert(data.message);
            } else {
                alert('An error occurred: ' + data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while submitting the form.');
        }
    };
    return (
        <div className="max-w-full mb-10">
            <form className="max-w-6xl mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-md" onSubmit={handleSubmit}>
                <div className="mb-6">
                    <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Meno a Priezvisko:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        onBlur={() => handleBlur('name', name)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
                </div>
                <div className="flex w-full">
                    <div className="mb-6 w-full">
                        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onBlur={() => handleBlur('email', email)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
                    </div>
                    <div className="mb-6 ml-4 w-full">
                        <label htmlFor="telefon" className="block text-gray-700 font-bold mb-2">Telefon:</label>
                        <input
                            type="text"
                            id="telefon"
                            value={telefon}
                            onChange={e => setTelefon(e.target.value)}
                            onBlur={() => handleBlur('telefon', telefon)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {errors.telefon && <p className="text-red-500 text-xs">{errors.telefon}</p>}
                    </div>
                </div>
                <div className="mb-6">
                    <label htmlFor="produkt" className="block text-gray-700 font-bold mb-2">Vyber produktu:</label>
                    <select
                        id="produkt"
                        value={produkt}
                        onChange={e => setProdukt(e.target.value)}
                        onBlur={() => handleBlur('produkt', produkt)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="" disabled>Vyberte možnosť</option>
                        {napln.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    {errors.produkt && <p className="text-red-500 text-xs">{errors.produkt}</p>}
                </div>
                <div className="mb-6">
                    <label htmlFor="mnozstvo" className="block text-gray-700 font-bold mb-2">Množstvo(v tonách):</label>
                    <input
                        type="text"
                        id="mnozstvo"
                        value={mnozstvo}
                        onChange={e => setMnozstvo(e.target.value)}
                        onBlur={() => handleBlur('mnozstvo', mnozstvo)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    {errors.mnozstvo && <p className="text-red-500 text-xs">{errors.mnozstvo}</p>}
                </div>
                <div className="mb-6">
                    <label htmlFor="prebratie" className="block text-gray-700 font-bold mb-2">Možnosť prebratia:</label>
                    <select
                        id="prebratie"
                        value={prebratie}
                        onChange={e => setPrebratie(e.target.value)}
                        onBlur={() => handleBlur('prebratie', prebratie)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="" disabled>Vyberte možnosť</option>
                        <option value="osobne">Osobne</option>
                        <option value="dodanie">Dodanie</option>
                    </select>
                    {errors.prebratie && <p className="text-red-500 text-xs">{errors.prebratie}</p>}
                </div>
                <div className="mb-6">
                    <label htmlFor="info" className="block text-gray-700 font-bold mb-2">Dodatočné info:</label>
                    <input
                        type="text"
                        id="info"
                        value={info}
                        onChange={e => setInfo(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-6 m-auto flex">
                    <input type="checkbox" className="" onChange={canOrderForm} />
                    <div className="ml-2">
                    Oboznámil(a) som sa s podmienkami ochrany osobných údajov a súhlasím so spracovaním údajov uvedených vo formulári
                    </div>
                </div>
                <button
                    type="submit"
                    className={`w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200 ${canOrder ? '' : 'opacity-50 cursor-not-allowed'}`}
                    disabled={!canOrder}
                >
                    Odošli
                </button>
            </form>
        </div>
    );
}

export default Formular;
