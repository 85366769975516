import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import Ponuka from './components/Ponuka';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Strkopiesky from './components/Strkopiesky';
import Kamenivo from './components/Kamenivo';
import Piesok from './components/Piesok';
import Okrasny from './components/Okrasny';
import 'animate.css';
import ContactTab from './components/ContactTab';
import Doprava from './components/Doprava';


const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/ponuka', element: <Ponuka /> },
  { path: 'ponuka/strkopiesky', element: <Strkopiesky /> },
  { path: 'ponuka/kamenivo', element: <Kamenivo /> },
  { path: 'ponuka/piesok', element: <Piesok /> },
  { path: 'ponuka/okrasny', element: <Okrasny />},
  { path: '/contact', element: <ContactTab />},
  { path: '/doprava', element: <Doprava />}
])

function App() {
  return (
        <RouterProvider router={router} />
  );
}

export default App;
