import Navbar from './Navbar';
import Footer from './Footer';
import 'animate.css';
import strkopiesky04 from '../resources/Dekoračný 14-16.jpeg';
import strkopiesky016 from '../resources/Dekoračný dunaj 16-22.jpeg';
import strkopiesky48 from '../resources/Dekoračný 16-32.jpeg';
import strkopiesky816 from '../resources/Dekoračný 63-120.jpeg';
import Formular from './Formular';
import { useEffect, useState } from 'react';
import { useLocation, ScrollRestoration } from 'react-router-dom';
function Okrasny() {
    const [items, setItems] = useState([]);
    const location = useLocation();
    useEffect(() => {
        handleWhatOrder();
    },[location.pathname]);

    const handleWhatOrder = () => {
        if(location.pathname === '/ponuka/okrasny'){
            setItems([
                'Frakcia 14/16 Okrasný',
                'Frakcia 16/22 Okrasný',
                'Frakcia 16/32 Okrasný',
                'Frakcia 63/120 Okrasný'
            ]);
        }
    };
    return (
        <div>
            <Navbar />
            <div className="text-center mt-48 text-5xl font-extrabold mb-16 text-gray-800">
                Naša <span className="text-red-600">ponuka</span>
            </div>
            <div className="flex flex-wrap justify-center max-w-full w-11/12 m-auto rounded-2xl gap-2">
              
                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={strkopiesky04} className="h-full w-full object-cover" alt="ŠTRKOPIESKY" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                            Dekoratívne kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 14/16
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný na výstavbu skaliek, vytváranie záhonov a v neposlednom rade skrášli okolie domu. Kamene nájdu svoje uplatnenie aj okolo domu na lepší odvod dažďovej vody.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>35,00 €</td>
                                    <td>42,00 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
              
                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={strkopiesky016} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                            Dekoratívne kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 16/22
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                        Vhodný na výstavbu skaliek, vytváranie záhonov a v neposlednom rade skrášli okolie domu. Kamene nájdu svoje uplatnenie aj okolo domu na lepší odvod dažďovej vody.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>32,50 €</td>
                                    <td>39,00 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={strkopiesky48} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                            Dekoratívne kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 16/32
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                        Vhodný na výstavbu skaliek, vytváranie záhonov a v neposlednom rade skrášli okolie domu. Kamene nájdu svoje uplatnenie aj okolo domu na lepší odvod dažďovej vody.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>35,00 €</td>
                                    <td>42,00 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={strkopiesky816} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                            Dekoratívne kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 63/120
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                        Vhodný na výstavbu skaliek, vytváranie záhonov a v neposlednom rade skrášli okolie domu. Kamene nájdu svoje uplatnenie aj okolo domu na lepší odvod dažďovej vody a ako náplň do pohľadových gabiónoch.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>21,00 €</td>
                                    <td>25,20 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="border-t border-gray-300 mt-10 pt-4 w-2/3 m-auto">
            </div>
            <Formular napln={items}/>
            <Footer />
            <ScrollRestoration />
        </div>
    );
}

export default Okrasny;
